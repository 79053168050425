import { Feather } from '@expo/vector-icons';
import { useRoute, useNavigation } from '@react-navigation/native';
import { ArrowBackHeader, Button, Input, TimePicker, Title, WebWrapper } from '_/components';
import { DATE_FORMAT, TIME_FORMAT } from '_/config/date';
import { colors } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { AppRoute } from '_/navigation/types';
import logger from '_/services/logger';
import { EventDate } from '_/services/models/date.model';
import { ErrorCode } from '_/services/models/enums/error-code.enum';
import { getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import { add, getHours, getMinutes, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView } from 'react-native';
import { Calendar } from 'react-native-calendars';
import { showMessage } from 'react-native-flash-message';

import {
  calendarTheme,
  customDisabledDates,
  selectedCustomMark,
  todayCustomMark,
} from '../DatePicker/styles';
import { locale } from '../Meeting/WeekCalendar/addLocale';
import { IEditEventScreenParams } from './interfaces';
import styles from './styles';

const EditEvents: React.FC = () => {
  const { today, maxDate, checkOpenCloseHourMin } = useDateContext();
  const route = useRoute();
  const { event } = route.params as IEditEventScreenParams;
  const startAtToDateType = parseISO(event.startAt);
  const endAtToDateType = parseISO(event.endAt);
  const { disabledDateData } = useSpaceContext();
  const { updateEvent, getTodayEvents } = useEventContext();
  const navigation = useNavigation<any>();
  const { t } = useTranslation();
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [markedDates, setMarkedDates] = useState<Record<string, any>>({});
  const [selectedNewTime, setSelectedNewTime] = useState<EventDate>({
    start: startAtToDateType,
    end: endAtToDateType,
  });

  const {
    isIntervalInvalid,
    hasStartTimeBeforeCurrent,
    setSelectedDay,
    selectedEventDate,
    setSelectedEventDate,
    setUpdateEventDate,
  } = useDateContext();

  const selectedDay = useMemo(
    () => formatDate(selectedNewTime.start, DATE_FORMAT),
    [selectedNewTime]
  );

  const handleSetSelectedDay = (day: string) => {
    setSelectedDay(day);

    setSelectedNewTime((prevState) => ({
      start: add(parseISO(day), {
        hours: getHours(prevState.start),
        minutes: getMinutes(prevState.start),
      }),
      end: add(parseISO(day), {
        hours: getHours(prevState.end),
        minutes: getMinutes(prevState.end),
      }),
    }));
  };

  const handleEditEvent = async () => {
    const startAt = add(parseISO(selectedDay), {
      hours: selectedNewTime.start.getHours(),
      minutes: selectedNewTime.start.getMinutes(),
    });

    const endAt = add(parseISO(selectedDay), {
      hours: selectedNewTime.end.getHours(),
      minutes: selectedNewTime.end.getMinutes(),
    });

    if (
      checkOpenCloseHourMin(
        startAt,
        endAt,
        event.slot?.space?.openAtHourMin,
        event.slot?.space?.closeAtHourMin
      )
    ) {
      showMessage({
        message: t('error'),
        description: t('workstationScreen.errorMessages.checkOpenCloseHourMinError', {
          openAt: event.slot?.space?.openAtHourMin ?? '00:00',
          closeAt: event.slot?.space?.closeAtHourMin ?? '23:59',
        }),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    setRequestIsLoading(true);
    const newData = {
      ...(newDescription && { description: newDescription }),
      startAt,
      endAt,
    };

    try {
      await updateEvent(event.id, newData);
      showMessage({
        message: t('success'),
        description: t('editEventScreen.successMessage'),
        backgroundColor: colors.successGreen,
      });
      setRequestIsLoading(false);
      navigation.navigate(AppRoute.HOME);
      await getTodayEvents();
    } catch (error: any) {
      let errorMessage = t('workstationScreen.errorMessages.reservationFail');
      if (error?.response?.data?.data?.code === ErrorCode.HAS_CONFLICT) {
        errorMessage = t('workstationScreen.errorMessages.scheduleConflict');
      } else if (error?.response?.data?.data?.code === ErrorCode.HAS_USER_CONFLICT) {
        errorMessage = t('workstationScreen.errorMessages.alreadyScheduled');
      } else if (error?.response?.data?.data?.code === ErrorCode.NO_PAST_EVENT_SCHEDULE) {
        errorMessage = t('workstationScreen.errorMessages.noPastReservations');
      } else if (error?.response?.data?.data?.code === ErrorCode.HAS_MAX_CAPACITY_REACHED) {
        errorMessage = t('workstationScreen.noAvailableSlots.maxCapacityReached');
      } else if (error?.response?.data?.data?.code === ErrorCode.OPEN_CLOSE_SPACE_ERROR) {
        errorMessage = t('workstationScreen.errorMessages.checkOpenCloseHourMinError', {
          openAt: event.slot?.space?.openAtHourMin ?? '00:00',
          closeAt: event.slot?.space?.closeAtHourMin ?? '23:59',
        });
      } else if (error?.response?.data?.data?.code === ErrorCode.ONLY_ADMIN) {
        errorMessage = t('workstationScreen.errorMessages.onlyAdmin');
      } else {
        errorMessage = t('editEventScreen.errorMessage');
      }
      setRequestIsLoading(false);
      showMessage({
        message: t('error'),
        description: errorMessage,
        backgroundColor: colors.errorRed,
      });
      logger(error);
    }
  };

  const selectedStartTime = useMemo(() => {
    return formatDate(selectedNewTime.start, TIME_FORMAT, { locale });
  }, [selectedNewTime]);

  const selectedEndTime = useMemo(() => {
    return formatDate(selectedNewTime.end, TIME_FORMAT, { locale });
  }, [selectedNewTime]);

  useEffect(() => {
    const eventDateStart = selectedEventDate.start.toString();
    const eventDateEnd = selectedEventDate.end.toString();
    const eventNewTimeStart = selectedNewTime.start.toString();
    const eventNewTimeEnd = selectedNewTime.end.toString();

    if (eventDateStart !== eventNewTimeStart || eventDateEnd !== eventNewTimeEnd) {
      setSelectedEventDate({
        start: selectedNewTime.start,
        end: selectedNewTime.end,
      });
    }
  }, [selectedNewTime]);

  const [newDescription, setNewDescription] = useState(event.description);

  useEffect(() => {
    const marks: Record<string, any> = {
      [formatDate(today, DATE_FORMAT)]: { selected: true, customStyles: todayCustomMark },
      [selectedDay]: {
        selected: true,
        customStyles: selectedCustomMark(event.slot?.space?.type),
      },
    };

    disabledDateData?.forEach(({ date }) => {
      marks[formatDate(parseISO(date), DATE_FORMAT)] = {
        selected: true,
        customStyles: customDisabledDates,
      };
    });

    setMarkedDates(marks);
  }, [disabledDateData, event.slot?.space?.type, selectedDay, today]);

  useEffect(() => {
    setUpdateEventDate({ start: new Date(event.startAt), end: new Date(event.endAt) });
    return () => {
      setUpdateEventDate(undefined);
    };
  }, []);

  return (
    <ScrollView style={{ flex: 1 }}>
      <WebWrapper>
        <View style={styles.wrapper}>
          <ArrowBackHeader>{t('editEventScreen.editEvent')}</ArrowBackHeader>
          <Title title={`${t('editEventScreen.newTitle')}?`} />
          <Input
            placeholder={t('editEventScreen.newTitle')}
            value={newDescription}
            onChange={(e) => setNewDescription(e.nativeEvent.text)}
          />
          <Title title={`${t('editEventScreen.newDate')}?`} />

          <Calendar
            theme={calendarTheme(event.slot?.space?.type)}
            minDate={today?.toISOString()}
            maxDate={maxDate?.toISOString()}
            onDayPress={(day) => handleSetSelectedDay(day.dateString)}
            markingType="custom"
            markedDates={markedDates}
            renderArrow={(direction) => (
              <Feather name={`chevron-${direction}` as any} size={28} color={colors.darkGrey} />
            )}
          />
        </View>
        <Title title={`${t('editEventScreen.newSchedule')}?`} />
        <TimePicker
          editable
          defaultSelectedStartTime={selectedStartTime}
          defaultSelectedEndTime={selectedEndTime}
          setEventDate={setSelectedNewTime}
          selectedNewTime={selectedNewTime}
        />
        <View style={styles.buttonWrapper}>
          <View
            style={{
              opacity: !selectedDay || isIntervalInvalid || hasStartTimeBeforeCurrent ? 0.2 : 1,
            }}
          >
            <Button
              backgroundColor={getSpaceTypeColor(event.slot?.space?.type)}
              textColor={colors.white}
              onPress={handleEditEvent}
              disabled={isIntervalInvalid || hasStartTimeBeforeCurrent}
              loading={requestIsLoading}
            >
              {t('editEventScreen.editEvent')}
            </Button>
          </View>
        </View>
      </WebWrapper>
    </ScrollView>
  );
};

export default EditEvents;
