import AsyncStorage from '@react-native-async-storage/async-storage';
import generalConfig from '_/config/general';
import { LanguageProps, languages } from '_/constants/languages';
import logger from '_/services/logger';
import { Lang } from '_/services/models/enums/lang.enum';
import localeEn from 'date-fns/locale/en-US';
import localeEs from 'date-fns/locale/es';
import localePt from 'date-fns/locale/pt-BR';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LanguageContextData {
  languages: LanguageProps[];
  language: LanguageProps;
  changeLanguage(langCode: string): void;
  locale: Locale;
}

type LangType = {
  children: React.ReactNode;
};

const LanguageContext = createContext<LanguageContextData>({} as LanguageContextData);

export const LanguageProvider: React.FC<LangType> = ({ children }) => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState<LanguageProps>(languages[0]);

  async function getLanguage(): Promise<void> {
    const storedLanguage = await AsyncStorage.getItem(generalConfig.languageKey);

    if (storedLanguage) {
      const storedLanguageObj = JSON.parse(storedLanguage);
      i18n.changeLanguage(storedLanguageObj?.code);
      setLanguage(storedLanguageObj);
    }
  }

  useEffect(() => {
    getLanguage();
  }, []);

  const changeLanguage = async (langCode: string) => {
    const item = languages.find((lang) => lang.code === langCode);

    if (!item) {
      return;
    }

    try {
      setLanguage(item);
      i18n.changeLanguage(item?.code);
      await AsyncStorage.setItem(generalConfig.languageKey, JSON.stringify(item));
    } catch (error) {
      logger(error);
    }
  };

  const locale = useMemo(() => {
    if (language.code === Lang.EN) {
      return localeEn;
    }
    if (language.code === Lang.ES) {
      return localeEs;
    }

    return localePt;
  }, [language.code]);

  return (
    <LanguageContext.Provider value={{ languages, language, changeLanguage, locale }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function useLanguage() {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within an LanguageProvider');
  }

  return context;
}
