import AsyncStorage from '@react-native-async-storage/async-storage';
import generalConfig from '_/config/general';
import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Lang } from '../models/enums/lang.enum';
import { resources } from './resources/index';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async (language: any) => {
    const persistedLocale = await AsyncStorage.getItem(generalConfig.languageKey);
    if (!persistedLocale) {
      return language(Lang.PTBR);
    }
    language(persistedLocale);
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

const initConfig: InitOptions = {
  fallbackLng: Lang.PTBR,
  resources,
  debug: false,
  compatibilityJSON: 'v3',
  cache: {
    enabled: true,
  },
  interpolation: {
    prefix: '${',
    suffix: '}',
  },
};

i18n
  .use(languageDetector as any)
  .use(initReactI18next)
  .init(initConfig);

export default i18n;
