import general from '_/config/general';
import { colors } from '_/constants/theme';
import { networkErrorMessage } from '_/helpers/networkError';
import { eventsApi } from '_/services/api';
import { ReqBase } from '_/services/api/types/ReqQuery';
import logger from '_/services/logger';
import { EventStatus } from '_/services/models/enums/event-status.enum';
import { getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import { MembersModel } from '_/services/models/members.model';
import { SlotsModel } from '_/services/models/slots.model';
import { endOfDay, startOfDay } from 'date-fns';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { RRule, Frequency } from 'rrule';

import {
  EventContextData,
  CreateReservationType,
  WeekDaysTypes,
  GetAvailableSlotsType,
  EventsResponse,
  EventUpdateType,
} from '../services/models/events.model';
import { useAuth } from './AuthContext';
import { useDateContext } from './DateContext';
import { useSlotContext } from './SlotContext';
import { useSpaceContext } from './SpaceContext';
import { ErrorCode } from '_/services/models/enums/error-code.enum';

const EventContext = createContext<EventContextData>({} as EventContextData);

type EventType = {
  children?: React.ReactNode;
};

export const EventProvider: React.FC<EventType> = ({ children }) => {
  const { member } = useAuth();
  const { spaceType } = useSpaceContext();
  const modalMessage = useRef<any>(null);
  const { t } = useTranslation();

  const [activeReservations, setActiveReservations] = useState<EventsResponse[]>([]);
  const [pastReservations, setPastReservations] = useState<EventsResponse[]>([]);
  const [meetingReservations, setMeetingReservations] = useState<EventsResponse[]>();
  const [hasActiveReservations, setHasActiveReservations] = useState(false);
  const [hasPastReservations, setHasPastReservations] = useState(false);
  const [byWeekDay, setByWeekDay] = useState<Partial<WeekDaysTypes[]>>();
  const [recurrenceInterval, setRecurrenceInterval] = useState<string | undefined>('1');
  const [freq, setFreq] = useState<Frequency>(RRule.WEEKLY);
  const [totalCurrentEvents, setTotalCurrentEvents] = useState<number>(0);
  const [recurrenceStr, setRecurrenceStr] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [availableListLoading, setAvailableListLoading] = useState<
    'endReached' | 'loadData' | null
  >(null);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [eventsConflicts, setEventsConflicts] = useState<string[]>([]);
  const [closedDates, setClosedDates] = useState<string[]>([]);
  const [userEventsConflicts, setUserEventsConflicts] = useState<string[]>([]);
  const [activeReservationsResponseTotal, setActiveReservationsResponseTotal] = useState(0);
  const [pastReservationsResponseTotal, setPastReservationsResponseTotal] = useState(0);
  const [todayReservations, setTodayReservations] = useState<EventsResponse[]>([]);

  const { currentSpace } = useSpaceContext();
  const { selectedEventDate } = useDateContext();

  const {
    setAvailableListPage,
    setAvailableList,
    availableListPage,
    setReservedList,
    setSlotsList,
    setAvailableListEndReached,
  } = useSlotContext();

  const createReservationEvent = async ({
    userId,
    memberId,
    slotId,
    startAtTime,
    endAtTime,
    recurrenceStr,
    dropdownField1OptionId,
    dropdownField2OptionId,
    textField1Value,
    textField2Value,
    description,
    createEvent,
    costCenter,
  }: CreateReservationType) => {
    setLoading(true);

    if (createEvent !== true) {
      setCalendarLoading(true);
    }

    try {
      const result = await eventsApi.insert({
        createEvent,
        createdByUserId: userId,
        createdToMemberId: memberId,
        slotId,
        startAt: startAtTime,
        endAt: endAtTime,
        costCenter,
        ...(description && { description }),
        ...(dropdownField1OptionId && { dropdownField1OptionId }),
        ...(dropdownField1OptionId && { dropdownField2OptionId }),
        ...(textField1Value && { textField1Value }),
        ...(textField2Value && { textField2Value }),
        ...(recurrenceStr && { recurrenceStr }),
      });

      const { conflicts } = result as { conflicts?: Date[] };

      setAvailableDates(result.availableDates || []);
      setEventsConflicts(result.eventsConflicts || []);
      setUserEventsConflicts(result.userEventsConflicts || []);
      setClosedDates(result.disabledDatesConflicts || []);

      setCalendarLoading(false);

      if (conflicts && conflicts?.length > 0) {
        showMessage({
          message: t('success'),
          description: t('workstationScreen.successMessages.reservationButWithConflict'),
          backgroundColor: colors.successGreen,
        });
      }

      if (createEvent) {
        showMessage({
          message: t('success'),
          description: t('workstationScreen.successMessages.reservationCreated'),
          backgroundColor: colors.successGreen,
        });
      }
    } catch (error) {
      setLoading(false);
      networkErrorMessage(error);
      logger(error);
      throw error;
    } finally {
      setLoading(false);
      if (createEvent !== true) {
        setCalendarLoading(false);
      }
    }
  };

  const getEventsbyMemberId = async (member: Partial<MembersModel>) => {
    return await eventsApi.getList({
      createdToMemberId: member.id,
    });
  };

  async function getActiveEvents(skip = 0, endAt?: Date | null, isloadMore?: boolean) {
    setEventsLoading(true);

    const params = {
      'status[$nin]': [EventStatus.CANCELED, EventStatus.CHECKEDOUT],
      createdToMemberId: member?.id,
      $limit: general.apiLimit,
      $skip: skip,
      '$sort[startAt]': 1,
      'startAt[$gte]': startOfDay(new Date()),
      ...(endAt && { 'endAt[$lte]': endAt }),
    };

    const { data, total } = await eventsApi.getList(params);

    if (isloadMore) {
      setActiveReservations([...activeReservations, ...data]);
    } else {
      setActiveReservations(data);
    }

    setActiveReservationsResponseTotal(total as number);
    setEventsLoading(false);

    return data;
  }

  async function getPastEvents(skip = 0, isloadMore?: boolean) {
    setEventsLoading(true);

    const params = {
      'status[$ne]': EventStatus.CANCELED,
      createdToMemberId: member?.id,
      $limit: general.apiLimit,
      $skip: skip,
      '$sort[startAt]': -1,
      '$or[0][status]': EventStatus.CHECKEDOUT,
      '$or[1][startAt][$lt]': startOfDay(new Date()),
    };

    const { data, total } = await eventsApi.getList(params);
    setPastReservationsResponseTotal(total as number);

    if (isloadMore) {
      setPastReservations([...pastReservations, ...data]);
    } else {
      setPastReservations(data);
    }

    setEventsLoading(false);

    return data;
  }

  async function getTodayEvents() {
    const params = {
      'status[$nin]': [EventStatus.CANCELED, EventStatus.CHECKEDOUT],
      createdToMemberId: member?.id,
      $limit: general.apiLimit,
      '$sort[startAt]': 1,
      'startAt[$gte]': startOfDay(new Date()),
      'endAt[$lte]': endOfDay(new Date()),
    };

    const { data } = await eventsApi.getList(params);

    const checkoutFilter = data.filter((item) => item.checkoutAt === null);

    setTodayReservations(checkoutFilter);
  }

  const getEvents = useCallback(async () => {
    const activeEvents = await getActiveEvents();

    await getTodayEvents();

    return activeEvents;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMeetings = useCallback(
    async ({ slotId, startAt, endAt }: { slotId: string; startAt: Date; endAt: Date }) => {
      const result = await eventsApi.getList({
        slotId,
        'startAt[$gte]': startAt,
        'endAt[$lte]': endAt,
        'status[$ne]': EventStatus.CANCELED,
      });

      return result.data;
    },
    []
  );

  const cancelEvent = async (id: string) => {
    setLoading(true);
    try {
      const result = await eventsApi.update(id, {
        status: EventStatus.CANCELED,
      });

      await getEvents();

      showMessage({
        message: t('success'),
        description: t('workstationScreen.successMessages.cancelledReservation'),
        backgroundColor: colors.successGreen,
      });
      return result;
    } catch (error) {
      setLoading(false);
      networkErrorMessage(error);
      if (error?.response?.data?.data?.code === ErrorCode.ONLY_ADMIN) {
        showMessage({
          message: t('error'),
          description: t('workstationScreen.errorMessages.onlyAdmin'),
          backgroundColor: colors.errorRed,
        });
      } else {
        showMessage({
          message: t('error'),
          description: t('workstationScreen.errorMessages.cancelFail'),
          backgroundColor: colors.errorRed,
        });
      }

      logger(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const cancelRecurrenceEvent = async (id: string, recurrenceId?: string) => {
    setLoading(true);
    try {
      await eventsApi.update(
        id,
        {
          status: EventStatus.CANCELED,
        },
        {
          params: {
            recurrenceId,
          },
        }
      );

      await getEvents();

      showMessage({
        message: t('success'),
        description: t('workstationScreen.successMessages.cancelledRecurring'),
        backgroundColor: colors.successGreen,
      });
    } catch (error) {
      setLoading(false);
      networkErrorMessage(error);
      if (error?.response?.data?.data?.code === ErrorCode.ONLY_ADMIN) {
        showMessage({
          message: t('error'),
          description: t('workstationScreen.errorMessages.onlyAdmin'),
          backgroundColor: colors.errorRed,
        });
      } else {
        showMessage({
          message: t('error'),
          description: t('workstationScreen.errorMessages.cancelRecurringFail'),
          backgroundColor: colors.errorRed,
        });
      }
      logger(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getSpaceEvents = useCallback(
    async ({ spaceId, startAtTime, endAtTime, ...rest }: GetAvailableSlotsType & ReqBase<any>) => {
      return await eventsApi.getSpaceEvents({ spaceId, startAtTime, endAtTime, ...rest });
    },
    []
  );

  const getAvailableList = async ({
    limit,
    reset,
    startAtTime,
    endAtTime,
    spaceId,
    slotName,
  }: GetAvailableSlotsType) => {
    if (availableListLoading) {
      return;
    }

    const page = reset ? 0 : availableListPage + 1;

    if (reset && availableListLoading === null) {
      setAvailableListLoading('loadData');
    } else if (!reset && availableListLoading === null) {
      setAvailableListLoading('endReached');
    }

    setAvailableListPage(page);

    const data = await getSpaceEvents({
      spaceId,
      startAtTime,
      endAtTime,
      $limit: limit,
      $skip: page * (limit || 0),
      ...(slotName && { slotName }),
    });
    setAvailableListEndReached(!!limit && data?.slots.length < limit);
    setSlotsList(data.slots);
    setTotalCurrentEvents(data.currentCapacity);
    const slotsWithNoEvents = data.slots.filter(
      (slot: SlotsModel) =>
        slot.events?.filter((event) => !event.checkoutAt || event.status !== EventStatus.CHECKEDOUT)
          .length === 0
    );

    const filteredReservations = data.slots.flatMap((slot: SlotsModel) =>
      slot.events?.map((event) => event)
    ) as EventsResponse[];
    setReservedList(filteredReservations);
    if (reset) {
      setAvailableList(slotsWithNoEvents);
    } else {
      setAvailableList((prevSlots) =>
        prevSlots.concat(slotsWithNoEvents.filter((slot: any) => prevSlots.indexOf(slot) === -1))
      );
    }
    setAvailableListLoading(null);
  };

  const getReservedList = useCallback(
    async ({
      spaceId,
      startAtTime,
      endAtTime,
      ...rest
    }: {
      spaceId: string;
      startAtTime?: Date;
      endAtTime?: Date;
    }) => {
      const result = await eventsApi.getSpaceEvents({ spaceId, startAtTime, endAtTime, ...rest });
      const filteredReservations: EventsResponse[] = [];

      result.slots.forEach((slot: SlotsModel) =>
        slot.events?.forEach((event) =>
          filteredReservations.push(event as unknown as EventsResponse)
        )
      );
      setReservedList(filteredReservations);

      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setReservationColor = useCallback(
    (createdToMember: string | undefined, description: string | undefined) => {
      if (createdToMember === member?.id) {
        return getSpaceTypeColor(spaceType);
      }
      if (description === 'Limpeza') {
        return colors.yellow;
      } else {
        return colors.lightGrey;
      }
    },
    [member?.id, spaceType]
  );

  const meetingEvents = useMemo(() => {
    return meetingReservations?.map((reservation) => {
      return {
        id: reservation.id,
        description: reservation.description,
        startDate: reservation.checkinAt || reservation.startAt,
        endDate: reservation.checkoutAt || reservation.endAt,
        color: setReservationColor(reservation.createdToMemberId, reservation.description),
        source: reservation.user?.pictureThumbUrl,
        name: reservation.user?.name,
        slotId: reservation.slotId,
      };
    });
  }, [meetingReservations, setReservationColor]);

  const maxCapacityReached = useMemo(() => {
    if (currentSpace.maxCapacity) {
      return totalCurrentEvents >= currentSpace.maxCapacity;
    }
    return false;
  }, [currentSpace, totalCurrentEvents]);

  const updateEvent = useCallback(async (id: string, eventData: EventUpdateType) => {
    return await eventsApi.update(id, {
      startAt: eventData.startAt,
      endAt: eventData.endAt,
      description: eventData.description,
    });
  }, []);

  useEffect(() => {
    setTotalCurrentEvents(0);
  }, [selectedEventDate]);

  return (
    <EventContext.Provider
      value={{
        createReservationEvent,
        getEventsbyMemberId,
        getEvents,
        activeReservations,
        pastReservations,
        hasActiveReservations,
        setHasActiveReservations,
        setHasPastReservations,
        hasPastReservations,
        cancelEvent,
        setByWeekDay,
        byWeekDay,
        recurrenceInterval,
        setRecurrenceInterval,
        setFreq,
        freq,
        recurrenceStr,
        setRecurrenceStr,
        cancelRecurrenceEvent,
        getSpaceEvents,
        loading,
        setLoading,
        calendarLoading,
        setCalendarLoading,
        getMeetings,
        getAvailableList,
        getReservedList,
        availableListLoading,
        modalMessage,
        meetingReservations,
        setMeetingReservations,
        meetingEvents,
        availableDates,
        eventsConflicts,
        userEventsConflicts,
        closedDates,
        maxCapacityReached,
        updateEvent,
        getActiveEvents,
        getPastEvents,
        activeReservationsResponseTotal,
        pastReservationsResponseTotal,
        getTodayEvents,
        todayReservations,
        eventsLoading,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export function useEventContext(): EventContextData {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error('useEvent must be used within an EventProvider');
  }

  return context;
}
