import { Lang } from '_/services/models/enums/lang.enum';

export interface LanguageProps {
  key: number;
  lang: string;
  code: Lang;
  format: {
    day_month: string;
    full_date: string;
    full_date_short: string;
  };
}

export const languages: LanguageProps[] = [
  {
    key: 0,
    lang: 'Português',
    code: Lang.PTBR,
    format: {
      day_month: "dd 'de' MMMM",
      full_date: "dd 'de' MMMM 'de' yyyy",
      full_date_short: 'dd/MM/yyyy',
    },
  },
  {
    key: 1,
    lang: 'English',
    code: Lang.EN,
    format: {
      day_month: 'MMMM dd',
      full_date: 'MMMM dd, yyyy',
      full_date_short: 'yyyy/MM/dd',
    },
  },
  {
    key: 2,
    lang: 'Español',
    code: Lang.ES,
    format: {
      day_month: "dd 'de' MMMM",
      full_date: "d 'de' MMMM 'de' y",
      full_date_short: 'dd/MM/y',
    },
  },
];
