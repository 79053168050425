import { Lang } from '_/services/models/enums/lang.enum';

import en from './en.json';
import es from './es.json';
import ptBR from './pt-BR.json';

export const resources = {
  [Lang.PTBR]: ptBR,
  [Lang.EN]: en,
  [Lang.ES]: es,
};
